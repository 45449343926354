<template>
  <div>
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :hiddenLang="hiddenLang"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-project-create"
    />
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="itemsPerPage"
        :data="items"
        :searchKeys="searchKeys"
        pagination
        search
      >
        <div 
          slot="header" 
          class="flex items-center flex-grow justify-between">
          <AddNewContent
            :allowLangs="['th']"
            :show-popup="false"
            name="lh-content-project-review-create"
          />

          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page" /> 
        </div>

        <template slot="thead">
          <vs-th sort-key="title">ชื่อ Project Review</vs-th>
          <vs-th sort-key="description">รายละเอียด</vs-th>
          <vs-th sort-key="position">ลำดับรูปภาพ</vs-th>
          <vs-th sort-key="published_date">วันที่เผยแพร่</vs-th>
          <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{`${tr.title} `}}
                <a
                @click.stop="" 
                :href="websiteUrl.concat('/', tr.lang, '/review/', tr.slug)" 
                target="_blank"><link-icon size="1.0x" /></a></td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.description }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.show_on_home ? tr.position : '' }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ publicDateFormat(tr.published_date) }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="brand-priority">{{ updatedAtDatetimeFormat(tr.updated_at) }}</p>
              </td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <div class="flex space-x-2">
                  <feather-icon
                    class="cursor-pointer"
                    icon="CopyIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    @click.prevent="duplicateContent(tr)"
                  />
                  <router-link
                    :to="{ name: `${routePrefix}-edit`, params: { id: tr.id }}"
                    class="pt-2 color-text-link hover:text-primary">
                    <feather-icon
                      icon="EditIcon"
                      svg-classes="w-5 h-5 stroke-current hover:text-primary"
                    />
                  </router-link>
                  <feather-icon
                    class="cursor-pointer"
                    icon="TrashIcon"
                    svg-classes="w-5 hover:text-danger h-5 stroke-current"
                    @click.prevent="deleteData(tr.id)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from '@vue/composition-api'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import AddNewContent from '@/components/AddNewContent'
import PopupLanguage from '@/components/PopupLanguage'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import useProjectReviewUtil from '@/use/useProjectReviewUtil'
import omit from 'lodash/omit'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import env from '@/env'
import { getUniqueValue } from '@/helper/Utils'
import { LinkIcon } from 'vue-feather-icons'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'ProjectReviewListView',
  components: { PopupLanguage, AddNewContent, LhDropdownPerPage, LinkIcon, CustomTable },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'projectReview')
    const { moment, updatedAtDatetimeFormat } = useDatepicker()
    const { formData, prepareFormData } = useProjectReviewUtil(ctx)

    const hiddenLang = ref([])
    const allItems = ref([])

    crudFunction.routePrefix.value = 'lh-content-project-review'

    const items = computed(() => {
      return orderBy(crudFunction.result.value, ['position', 'updated_at'], ['asc', 'desc'])
    })

    onMounted(async () => {
      allItems.value = await ctx.root.$store.dispatch(
        'projectReview/fetchAll',
      )
    })

    const handlerDuplicateContentFromCRUD = async (
      formData,
      prepareFormData,
      langToDuplicationContent,
    ) => {
      ctx.root.$vs.loading()
      await ctx.root.$store.dispatch('locale/changeLang', langToDuplicationContent)
      const result = await ctx.root.$store.dispatch(
        'projectReview/fetchOne',
        crudFunction.contentDuplicateId.value,
      )
      await prepareFormData(result)
      const activeUser = ctx.root.$store.state.AppActiveUser
      const email = get(activeUser, 'email', '')
      const created_by = email
      const updated_by = email
      const items = crudFunction.result.value

      const title = formData.title.concat('_copy')
      formData.title = getUniqueValue(items, title, 'title')

      // can copy and not concat
      // if (formData.slug) {
      //   const slug = formData.slug.concat('_copy')
      //   formData.slug = getUniqueValue(items, slug, 'slug')
      // }
      // if (formData.custom_slug) {
      //   const custom_slug = formData.custom_slug.concat('_copy')
      //   formData.custom_slug = getUniqueValue(items, custom_slug, 'custom_slug')
      // }
      
      const resultDuplicate = await ctx.root.$store.dispatch(
        'projectReview/addItem', 
        {
          ...omit(formData, ['id', 'revision_id', 'updated_at']),
          parent_id: formData.id,
          created_by,
          updated_by
        }
      )

      crudFunction.isPopupDuplicateActive.value = false
      await ctx.root.$vs.loading.close()
      await ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: {
          id: get(resultDuplicate, 'content.id'),
        },
      })
    }

    const handlerDuplicateContent = (langToDuplicationContent) => {
      handlerDuplicateContentFromCRUD(formData, prepareFormData, langToDuplicationContent)
    }

    const publicDateFormat = (value) => {
      return moment(value).format('DD MMM YYYY')
    }

    const duplicateContent = async (data) => {
      const existLang = {}

      allItems.value.forEach((value) => {
        const langValue = value.lang
        const customSlug = value.custom_slug
        
        if (customSlug === data.custom_slug) existLang[langValue] = 1
      })

      const keyLang = Object.keys(existLang)
      hiddenLang.value = !isEmpty(keyLang) ? keyLang : []
      crudFunction.duplicateContent(data.id)
    }

    return {
      ...crudFunction,
      items,
      handlerDuplicateContent,
      publicDateFormat,
      updatedAtDatetimeFormat,
      websiteUrl: `${env.VUE_APP_WEBSITE_URL}`.replace(/\/$/, ""),
      searchKeys: ['title', 'slug', 'description', 'position'],
      hiddenLang,
      duplicateContent
    }
  },
}
</script>

<style scoped></style>
